.formio-form {
  @import "node_modules/bootstrap/dist/css/bootstrap.min.css";
  @import "node_modules/formiojs/dist/formio.form.min.css";
}

.formio-component {
  @apply text-gray-900 font-sans place-content-center;
}

.formio-component label {
  @apply text-sm font-medium p-0 mb-2;
}

.formio-component .formio-errors .error {
  @apply text-red-600;
}

.formio-component .form-group.formio-component {
  @apply mb-2;
}

.formio-component-tabs .card {
  @apply bg-white rounded-lg border shadow-md;
}

.formio-component-tabs .card-header {
  @apply bg-transparent border-0 p-0 pt-2;  
}


.formio-component-tabs .card-body {
  @apply pb-0 px-3 sm:px-4;
}

.formio-component-tabs .card-header ul.nav-tabs {
  @apply mx-0 px-2 sm:px-4 border-b border-gray-200 shadow-md mt-2 overflow-y-auto lg:overflow-y-visible flex flex-nowrap scroll-hidden;
}

.formio-component-tabs .card-header ul.nav-tabs li {
  @apply border-current px-2 pb-2 whitespace-nowrap text-base border-b border-transparent;
}

.formio-component-tabs .card-header ul.nav-tabs li a {
  @apply p-0 border-0 text-gray-600 font-normal;
}

.formio-component-tabs .card-header ul.nav-tabs li.active {
  @apply border-b border-primary-600;
}

.formio-component-tabs .card-header ul.nav-tabs li.active a {
  @apply font-semibold text-primary-600;
}

.formio-component input.form-control {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}


.formio-component-select .choices {
  @apply px-0 flex items-center w-full h-10 text-lg font-bold text-gray-900 bg-gray-50 rounded-lg;
}

.formio-component-select .choices .form-control {
  @apply bg-transparent;
}

.formio-component-select .choices__list--dropdown {
  @apply font-normal absolute inset-x-0 z-10 py-1 text-base list-none bg-white rounded divide-y divide-gray-100 shadow z-20 hidden;
}

.formio-component-select .choices__list--dropdown.is-active {
  @apply block;
}

.formio-component-select .choices__list--dropdown .choices__list {
  @apply divide-y divide-gray-100;
}

.formio-component-select .choices__list--dropdown .choices__item {
  @apply block py-2 px-4 text-sm text-gray-700;
}

.formio-component-select .choices__list--dropdown .choices__item:hover {
  @apply bg-primary-50;
}

.formio-component-select .choices__item--selectable {
  @apply p-0;
}

.formio-component-select .choices__list--single {
  @apply align-bottom;
}

.formio-component-select .formio-select-autocomplete-input {
  @apply p-0;
}

.formio-component-select .form-control.is-invalid {
  background-position: right calc(1.375em + 0.1875rem) center;
}

.formio-component-tags .choices__inner {
  @apply flex items-center py-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}

.formio-component-tags .choices__item.choices__item--selectable {
  @apply mb-0;
}

.formio-component-tags .choices__input.choices__input--cloned {
  @apply mb-0;
}

.formio-component-tags input:focus {
  @apply ring-0;
}

.formio-component-tags .choices__item.choices__item--selectable {
  @apply px-2 py-1 rounded text-sm;
}

.formio-component-datetime .input-group {
  @apply flex-nowrap items-stretch;
}

.formio-component-datetime .input-group-append {
  @apply ml-[-10px] z-10;
}

.formio-component-datetime .input-group-text {
  @apply h-full;
}

.formio-component-day .row {
  @apply grid grid-cols-3
}

.formio-component-day select[name="month"] {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}

.formio-component-day .formio-select-autocomplete-input {
  @apply p-0;
}

.formio-component .form-check-input:checked {
  @apply bg-gray-600 border-primary-700;
}

.formio-component .form-radio-input:checked {
  @apply bg-gray-600 border-primary-700;
}

.formio-form .formio-component-textarea div.formio-editor-read-only-content {
  @apply bg-gray-200 border border-none
}

.formio-component-textarea{
  @apply bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full;
} 

input[type="radio"]:checked + label {
  font-weight: bold;

}

[type='checkbox']:checked, [type='radio']:checked, .dark [type='checkbox']:checked, .dark [type='radio']:checked {
  @apply bg-gray-600 border-primary-700;
}



.formio-component-button {
  @apply sm:mt-4 px-0 flex justify-center flex flex-col ;
}


.formio-component-button .btn-primary {
  @apply w-full sm:w-auto text-white bg-primary-600 border-primary-600 hover:bg-primary-800 focus:border-2 focus:border-primary-400 focus:bg-primary-700 focus:shadow-none;
}

label.col-form-label {
  font-weight: bold;
 }

 .specSpacer {
  height: 0px;
 }
 
 .formio-component .form-radio{
  margin-top: 0.0rem
 }

 .formio-form .field-required:after, .formio-form .tab-error::after {
  content: "*";
    visibility:hidden;
 }

 .formio-form .formio-component-radio.formio-component-label-hidden.required .form-check-label:before{
  content: "*";
    visibility:hidden;
 }

 .sr-only{visibility: hidden;overflow: hidden;position: absolute;}


.formio-component-button div[ref="buttonMessageContainer"].has-error {
  @apply text-red-600 text-sm text-center leading-4 mt-2;
}

.formio-component .form-control.is-invalid {
  @apply border-red-600;
}

.formio-component .choices [hidden] {
  @apply hidden;
}


.formio-component-textarea .card-body {
  @apply bg-gray-200 mb-0 mx-0 border border-none !important;
}

.htmlLabelHidden {
  @apply visible md:invisible;
 }

.labelHidden .col-form-label  {
  @apply visible md:invisible;
}

.labelHidden .form-check-label  {
  @apply invisible md:invisible;
}

.labelHidden .form-check-input  {
  @apply visible md:visible;
}



